import { device } from 'src/styles/breakpoints'
import { green, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const CustomSection = styled.section`
  background-color: ${green.extraDark};

  button {
    min-width: 100%;
  }

  .gatsby-image-wrapper {
    @media ${device.desktopXXL} {
      max-width: 456px;
    }
  }
`

export const NumberDiv = styled.div`
  background: ${orange.extra};
  width: 40px;
  height: 40px;
  border-radius: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  font-family: "Inter", Helvetica, sans-serif;
`
